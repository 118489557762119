import uuid from 'uuid';
import * as routersWrapper from '../wrappers/routers';

function createPageSEOData(pageTitle, isPrivate) {
  return {
    title: isPrivate ? pageTitle : '{userName} | ' + pageTitle,
    description: '',
    keywords: '',
    noIndex: isPrivate.toString(),
  };
}

async function getMembersAreaRouters(editorSDK) {
  const routers = await routersWrapper.getAll(editorSDK);

  if (routers.length === 0) {
    throw new Error('Could not find both routers in #getMembersAreaRouters');
  }

  const publicRouter = routers.find((r) => r.config.type === 'public');

  if (!publicRouter) {
    throw new Error('Could not find the public router in #getMembersAreaRouters');
  }

  const privateRouter = routers.find((r) => r.config.type === 'private');

  if (!privateRouter) {
    throw new Error('Could not find the private router in #getMembersAreaRouters');
  }

  return { publicRouter, privateRouter };
}

function connectPagesToRouterRef({ editorSDK, pages, pagesUids, routerRef }) {
  const promises = pages.map((page, i) =>
    routersWrapper.connectPageToRouter({
      editorSDK,
      pageRef: page.pageData.pageRef,
      pageRoles: [pagesUids[i]],
      routerRef,
    }),
  );
  return Promise.all(promises);
}

function createPatternsAndConfigs({ pages, pagesUids }) {
  return pages.reduce((acc, page, i) => {
    const { title, isPrivate, pageUriSEO } = page.pageData;
    const pageLink = page.urlOverride || pageUriSEO;
    const pattern = isPrivate ? '/' + pageLink : '/{userName}/' + pageLink;
    const { appData, socialHome } = page.routerConfig || {};
    const seoData = createPageSEOData(title, isPrivate);

    acc[pattern] = { socialHome, appData, page: pagesUids[i], seoData, title, pageId: page.routerConfig.pageId };
    return acc;
  }, {});
}

async function connectPagesToRouter({ editorSDK, pages, router }) {
  const pagesUids = new Array(pages.length).fill(null).map(() => uuid.v4());
  const routerRef = await routersWrapper.getRouterRefByPrefix({ editorSDK, prefix: router.prefix });
  const createdRouterPatternsConfig = createPatternsAndConfigs({ pages, pagesUids });
  const oldRouterPatternsConfig = router.config.patterns || {};
  const newRouterPatternsConfig = { ...oldRouterPatternsConfig, ...createdRouterPatternsConfig };
  const newRouterConfig = { ...router.config, patterns: newRouterPatternsConfig };

  return Promise.all([
    connectPagesToRouterRef({ editorSDK, pages, pagesUids, routerRef }),
    routersWrapper.updateRouterConfig({ editorSDK, config: newRouterConfig, routerRef }),
  ]);
}

const getPatternByPageRole = ({ routers, pageRole }) => {
  const { privateRouter, publicRouter } = routers;
  const privatePatterns = privateRouter.config.patterns || {};
  const publicPatterns = publicRouter.config.patterns || {};

  const privatePatternEntry = Object.entries(privatePatterns).find((p) => p[1].page === pageRole);
  const publicPatternEntry = Object.entries(publicPatterns).find((p) => p[1].page === pageRole);

  if (privatePatternEntry) {
    return { patternKey: privatePatternEntry[0], router: privateRouter };
  } else if (publicPatternEntry) {
    return { patternKey: publicPatternEntry[0], router: publicRouter };
  } else {
    return {}; // No pattern can be found if the page is not in Members Area
  }
};

const deleteRouterPatternByPatternKey = async ({ editorSDK, router, patternKey }) => {
  const routerRef = await routersWrapper.getRouterRefByPrefix({ editorSDK, prefix: router.prefix });
  delete router.config.patterns[patternKey];
  return routersWrapper.updateRouterConfig({ editorSDK, routerRef, config: router.config });
};

function getRouterPatternDataByPageId(router, pageId) {
  if (router?.config?.patterns) {
    return Object.values(router.config.patterns).find((pattern) => pattern.appData.appPageId === pageId);
  }
}

export {
  getMembersAreaRouters,
  connectPagesToRouter,
  getPatternByPageRole,
  deleteRouterPatternByPatternKey,
  getRouterPatternDataByPageId,
};
