import { EditorSDK, PageRef, PageData } from '@wix/platform-editor-sdk';
import { SANTA_MEMBERS_APP_ID } from '../constants';

interface BaseParams {
  editorSDK: EditorSDK;
}

interface PageParams {
  pageRef: PageRef;
}

export async function updatePageData({
  editorSDK,
  pageRef,
  pageData,
}: BaseParams & PageParams & { pageData: PageData }) {
  await editorSDK.pages.data.update('', { pageRef, data: { ...pageData, indexable: false } });
}

export async function rename({ editorSDK, pageRef, title }: BaseParams & PageParams & { title: string }) {
  await editorSDK.pages.rename('', { pageRef, title });
}

export async function updatePagePassword({
  editorSDK,
  pageRef,
  plainPassword,
}: BaseParams & PageParams & { plainPassword: string }) {
  await editorSDK.pages.permissions.updatePagePassword('', { pageRef, plainPassword });
}

export async function removePagePassword({ editorSDK, pageRef }: BaseParams & PageParams) {
  await editorSDK.pages.permissions.removePagePassword('', { pageRef });
}

export async function addPage({
  editorSDK,
  pageTitle,
  pageUriSEO,
}: BaseParams & { pageTitle: string; pageUriSEO: string }) {
  return editorSDK.pages.add('', {
    title: pageTitle,
    shouldAddMenuItem: true,
    // @ts-ignore // componentType should be optional in platform typings
    definition: {
      data: {
        hidePage: false,
        managingAppDefId: SANTA_MEMBERS_APP_ID,
        pageUriSEO,
      },
    },
  });
}

export async function navigateToPageRef({ editorSDK, pageRef }: BaseParams & PageParams) {
  // @ts-ignore // Page link should be optional in platform typings
  await editorSDK.pages.navigateTo('', { pageRef });
}

export function getPageData({ editorSDK, pageRef }: BaseParams & PageParams) {
  return editorSDK.pages.data.get('', { pageRef });
}

export function getCurrentPage({ editorSDK }: BaseParams) {
  return editorSDK.pages.getCurrent('');
}

export function getHomePage({ editorSDK }: BaseParams) {
  return editorSDK.pages.getHomePage('');
}

export function remove({
  editorSDK,
  pageRef,
  shouldShowEditorRemovePanel,
  pageToNavigateAfterRemove,
}: {
  editorSDK: EditorSDK;
  pageRef: PageRef;
  shouldShowEditorRemovePanel: boolean;
  pageToNavigateAfterRemove: PageRef;
}) {
  return editorSDK.document.pages.remove('', { pageRef, shouldShowEditorRemovePanel, pageToNavigateAfterRemove });
}

export function getAllPages({ editorSDK }: BaseParams) {
  return editorSDK.document.pages.data.getAll('');
}

export async function getMainMembersPageRef({ editorSDK }: BaseParams) {
  const allPages = await editorSDK.document.pages.data.getAll('');
  const mainPage = allPages.find((page) => page.tpaPageId === 'member_info');
  return mainPage && { id: mainPage.id };
}

// @ts-ignore
export function getApplicationPages({ editorSDK }) {
  return editorSDK.pages.getApplicationPages();
}
