import { APP_TOKEN } from '../constants';

const historyTimeouts = {};

async function add(editorSDK, label) {
  await editorSDK.history.add(APP_TOKEN, { label });
}

async function addWithDelay(editorSDK, label, delay) {
  if (historyTimeouts[label]) {
    clearTimeout(historyTimeouts[label]);
  }

  historyTimeouts[label] = setTimeout(() => add(editorSDK, label), delay);
}

export { add, addWithDelay };
