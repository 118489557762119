const RTL_LOCALES = ['he', 'ar'];

const isRtlLocale = (locale) => RTL_LOCALES.includes(locale);

const getEditorLocale = async (editorSDK) => {
  editorSDK = editorSDK || window.editorSDK;
  return (await editorSDK) && editorSDK.environment && editorSDK.environment.getLocale();
};

const getSiteLocale = async (editorSDK) => {
  editorSDK = editorSDK || window.editorSDK;
  return (await editorSDK) && editorSDK.info && editorSDK.info.getLanguage();
};

export { isRtlLocale, getEditorLocale, getSiteLocale };
