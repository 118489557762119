const VIEW_SITE_MEMBERS = {
  type: 'view_site_members',
};

const OPEN_SIGNUP_SETTINGS = {
  type: 'open_signup_settings',
};

const getDeleteCustomPageAction = (t) => ({
  title: t('Pages_Panel_MemberMenu_Delete_Custom_Page'),
  event: 'removePage',
  icon: 'delete_icon',
});

const getAddPageAction = (t) => ({
  title: t('Pages_Panel_MemberMenu_Add_Page'),
  type: 'add_page',
  event: 'createBlankPage',
  icon: 'addPagePagesPanel',
});

const getAdvancedURLSettingsAction = (t) => ({
  title: t('Pages_Panel_MemberMenu_URLSettings_Link'),
  event: 'renameRouter',
  icon: 'globe',
});

const getDeleteMembersAreaAction = (t) => ({
  title: t('Pages_Panel_MemberMenu_Delete_Link'),
  event: 'uninstall',
  icon: 'delete_icon',
});

export {
  VIEW_SITE_MEMBERS,
  OPEN_SIGNUP_SETTINGS,
  getDeleteCustomPageAction,
  getAddPageAction,
  getAdvancedURLSettingsAction,
  getDeleteMembersAreaAction,
};
