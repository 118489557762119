import { InnerRoutesSortOptions } from '@wix/platform-editor-sdk';
import * as actions from './actions';
import * as pages from './pages';
import * as constants from '../constants';
import { isAppsManagerEnabled, isAddUninstallMAActionEnabled, isBWProfileEnabled } from '../../utils/experiments';

const getRouterActions = (t, hasCustomPages) => ({
  showOnAllModes: true,
  default: [
    actions.getAddPageAction(t),
    actions.VIEW_SITE_MEMBERS,
    actions.OPEN_SIGNUP_SETTINGS,
    actions.getAdvancedURLSettingsAction(t),
    hasCustomPages && actions.getDeleteCustomPageAction(t),
    actions.getDeleteMembersAreaAction(t),
  ].filter((a) => !!a),
});

const createAppManifest = async (editorSDK, t) => {
  const [appsManagerEnabled, shouldAddUninstallMAAction, bwProfileEnabled] = await Promise.all([
    isAppsManagerEnabled(),
    isAddUninstallMAActionEnabled(),
    isBWProfileEnabled(),
  ]);
  const applicationPages = await editorSDK.document.pages.getApplicationPages('');
  const sortInnerRoutes = bwProfileEnabled ? { default: InnerRoutesSortOptions.NO_SORT } : undefined;
  const addUninstallMAAction =
    shouldAddUninstallMAAction && applicationPages.length === 1 ? [actions.getDeleteMembersAreaAction(t)] : [];

  const appDescriptorManifest = {
    appDescriptor: {
      mainActions: [
        {
          title: t('Apps_Manager_Add_Member_Page'),
          actionId: 'addMemberPage',
          type: 'editorActions',
          icon: 'appManager_addElementsAction',
        },
      ],
      defaultActions: {
        learnMoreKB: 'b74bcbba-91c8-4951-a3de-a1dc698f6356',
      },
      customActions: [
        {
          title: t('Apps_Manager_Manage_Member_Pages'),
          actionId: 'openMembersAreaPagesPanel',
          type: 'editorActions',
          icon: 'appManager_pagesAction',
        },
        {
          title: t('Apps_Manager_Open_Members_Add_Panel'),
          actionId: 'openMembersAddPanel',
          type: 'editorActions',
          icon: 'appManager_addElementsAction',
        },
        {
          title: t('Apps_Manager_Open_Badges_Page'),
          actionId: 'openBadgesPage',
          type: 'dashboard',
          icon: 'appManager_settingsAction',
        },
        {
          title: t('Apps_Manager_Engage_With_Your_Members'),
          actionId: 'openSiteMembersDashboard',
          type: 'dashboard',
          icon: 'appManager_settingsAction',
        },
      ],
    },
  };

  return {
    ...(appsManagerEnabled ? appDescriptorManifest : {}),
    controllersStageData: {
      members: {
        default: {
          visibility: 'NEVER',
        },
      },
    },
    pages: {
      pageDescriptors: {
        default: { icon: 'membersAction' },
      },
      applicationSettings: {
        default: {
          displayName: t('Pages_Panel_MemberMenu_Title'),
          helpId: constants.MEMBERS_PAGES_PANEL_HELP_ID,
        },
      },
      applicationActions: getRouterActions(t, false),
      pageSettings: {
        default: [pages.PAGE_INFO, pages.PERMISSIONS, pages.SEO],
      },
      pageActions: {
        default: [],
        deletable: [
          {
            title: t('Pages_Panel_MemberMenu_Actions_Page_Delete'),
            icon: 'deleteAction',
            event: 'removePage',
          },
        ],
        readOnly: addUninstallMAAction,
      },
      sortInnerRoutes,
    },
  };
};

export { createAppManifest };
