import { log } from '../utils/monitoring';

let lastAction: Promise<any> = Promise.resolve();

export default async function next<T>(methodName: string, nextAction: () => Promise<T>): Promise<T> {
  try {
    await lastAction;
  } catch (e) {
    const tags = { methodName };
    const extra = { error: JSON.stringify(e), stack: JSON.stringify(e && e.stack) };
    log('Rejected promise in enforceSequentiality', { tags, extra });
    lastAction = Promise.resolve();
  }

  lastAction = lastAction.then(nextAction);
  return lastAction;
}
