import { APP_TOKEN } from '../constants';

function addApplication(editorSDK, widgetDef) {
  return editorSDK.tpa.add.application(APP_TOKEN, widgetDef);
}

function addComponent(editorSDK, options) {
  return editorSDK.tpa.add.component(APP_TOKEN, options);
}

function isApplicationInstalled({ editorSDK, appDefinitionId }) {
  return editorSDK.tpa.isApplicationInstalled(APP_TOKEN, { appDefinitionId });
}

function isAppSectionInstalled({ editorSDK, appDefinitionId, sectionId }) {
  return editorSDK.tpa.isAppSectionInstalled(APP_TOKEN, { appDefinitionId, sectionId });
}

function getDataByAppDefId({ editorSDK, appDefinitionId }) {
  return editorSDK.tpa.app.getDataByAppDefId(APP_TOKEN, appDefinitionId);
}

function getAllCompsByApplicationId({ editorSDK, applicationId }) {
  return editorSDK.tpa.app.getAllCompsByApplicationId(APP_TOKEN, applicationId);
}

async function getAppDefIdByTpaAppId({ editorSDK, tpaAppId }) {
  const components = await editorSDK.tpa.app.getAllCompsByApplicationId('', tpaAppId);
  return components?.[0]?.appDefinitionId;
}

export {
  addApplication,
  addComponent,
  isApplicationInstalled,
  isAppSectionInstalled,
  getDataByAppDefId,
  getAllCompsByApplicationId,
  getAppDefIdByTpaAppId,
};
