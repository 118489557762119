import { EditorSDK } from '@wix/platform-editor-sdk';
import { APP_TOKEN } from '../constants';
import { areTransactionsEnabled } from '../../utils/experiments';

type Action<T> = () => T | Promise<T>;

type EditorSdkWithTransactions<T> = {
  document: {
    transactions: {
      runAndWaitForApproval: (token: string, action: Action<T>, runs?: number) => Promise<T>;
    };
  };
};

const runAndWaitForApproval = async <T>(editorSDK: EditorSDK, action: Action<T>) => {
  const transactionsEnabled = await areTransactionsEnabled();
  if (!transactionsEnabled) {
    return action();
  }

  return (editorSDK as EditorSDK & EditorSdkWithTransactions<T>).document.transactions.runAndWaitForApproval(
    APP_TOKEN,
    action,
  );
};

export { runAndWaitForApproval };
