(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("_"));
	else if(typeof define === 'function' && define.amd)
		define("santa-members-editor-app", ["_"], factory);
	else if(typeof exports === 'object')
		exports["santa-members-editor-app"] = factory(require("_"));
	else
		root["santa-members-editor-app"] = factory(root["_"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__6__) {
return 