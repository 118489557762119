import { APP_TOKEN } from '../constants';

async function create(editorSDK, groupName) {
  const memberPagesGroup = await editorSDK.pagesGroup.getByGroupName(APP_TOKEN, { groupName });
  if (memberPagesGroup) {
    await editorSDK.pagesGroup.remove(APP_TOKEN, { pagesGroupPointer: memberPagesGroup });
  }
  await editorSDK.pagesGroup.create(APP_TOKEN, { groupName });
}

async function addComponentToGroup(editorSDK, groupName, componentPointer) {
  const pagesGroupPointer = await editorSDK.pagesGroup.getByGroupName(APP_TOKEN, { groupName });
  await editorSDK.components.modes.showComponentOnlyOnPagesGroup(APP_TOKEN, { componentPointer, pagesGroupPointer });
}

async function addPageToGroup(editorSDK, groupName, pageId) {
  const pagesGroupPointer = await editorSDK.pagesGroup.getByGroupName(APP_TOKEN, { groupName });
  await editorSDK.pagesGroup.addPageToPagesGroup(APP_TOKEN, { pagesGroupPointer, pageId });
}

async function remove(editorSDK) {
  const pagesGroupsRefs = await editorSDK.pagesGroup.getAll();
  for (const pageGroupRef of pagesGroupsRefs) {
    await editorSDK.pagesGroup.remove(APP_TOKEN, { pagesGroupPointer: pageGroupRef });
  }
}

export { addComponentToGroup, addPageToGroup, create, remove };
