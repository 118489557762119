import { toMonitored } from '../../utils/monitoring';
import * as pagesWrapper from '../wrappers/pages.ts';
import { APP_TOKEN } from '../constants';
import { registerAlwaysAvailableApps } from '../services/integration.ts';
import * as pagesService from '../services/pages';

const refreshManifest = async (editorSDK) => {
  const applicationPages = await editorSDK.document.pages.getApplicationPages(APP_TOKEN);
  if (applicationPages.length === 1) {
    await editorSDK.document.application.reloadManifest();
  }
};

const removeMembersAreaPage = async (options) => {
  await toMonitored('removePage.platform', () => removeMembersAreaPagePlatform(options));
  await registerAlwaysAvailableApps(options.editorSDK);
  await refreshManifest(options.editorSDK);
};

const removeMembersAreaPageByPageId = async ({ pageId, appDefinitionId, editorSDK }) => {
  const page = await pagesService.getPageByIntegrationApp({ editorSDK, app: { pageId, appDefinitionId } });
  return removeMembersAreaPage({
    editorSDK,
    id: page.id,
  });
};

const getPageToNavigateAfterRemove = async (editorSDK, { id }) => {
  const allPages = await pagesWrapper.getAllPages({ editorSDK });
  const currentPageRef = await pagesWrapper.getCurrentPage({ editorSDK });
  let navigateTo = currentPageRef;
  const pageToDelete = allPages.find((p) => p.id === id);
  if (currentPageRef.id === pageToDelete.id) {
    navigateTo = await pagesWrapper.getMainMembersPageRef({ editorSDK });
  }
  return navigateTo;
};

const removeMembersAreaPagePlatform = async ({ editorSDK, id }) => {
  const allPages = await pagesWrapper.getAllPages({ editorSDK });
  const page = allPages.find((p) => p.id === id);
  if (!page) {
    return;
  }
  const pageRef = { id: page.id, type: 'DESKTOP' };

  return pagesWrapper.remove({
    editorSDK,
    pageRef,
    shouldShowEditorRemovePanel: false,
    pageToNavigateAfterRemove: await getPageToNavigateAfterRemove(editorSDK, { id }),
  });
};

export { removeMembersAreaPage, removeMembersAreaPageByPageId };
